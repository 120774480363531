.warning {
  background: #ba0000;
  color: white;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  z-index: 999999;
  font-weight: bold;
  text-align: center;
}

@supports (display: grid) {
  .warning {
    display: none;
  }
}