.testimonials-button {
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
  background: $primary-color;
  border: none;
  padding: 20px;
  color: white;
  font-size: 1.1rem;
  border-radius: 100px;
  outline: none;
  transition: transform .3s ease, box-shadow .3s ease, width 1s ease;
  transform: rotate(-7deg);
  border: 8px solid white;
  &:hover, &:focus {
    transform: scale(1.2);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
}

#contact .testimonials {
  //background: rgba(255, 255, 255, 0.5);
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto 20px;
  //padding: DON'T USE!;
  > *:first-child {
    margin-top: 20px;
  }
  > *:last-child {
    margin-bottom: 20px;
  }
  
  blockquote {
    margin-bottom: 20px;
    background: white;
    padding: 30px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    
    &:before {
      content: '\f10e';
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 200px;
      position: absolute;
      right: 20px;
      line-height: 1;
      top: 10px;
      color: rgba(105, 118, 128, 0.05);
      z-index: 0;
    }
    
    * {
      // Moves content in front of pseudo-element
      position: relative;
    }
        
    p {
      font-style: italic;
      font-size: 1.2rem;
      font-weight: normal;
      text-align: left;
      line-height: 1.7;
      
      strong {
        font-style: normal;
      }
    }

    > img {
      height: 150px;
      border-radius: 20px 50% 50% 50%;
      float: left;
      shape-outside: border-box;
      padding: 10px;
      margin-left: -10px;
      margin-top: -5px;
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.5));
    }
    
    footer {
      font-size: 1.3rem;
      text-align: right;
      margin-top: 10px;
      font-weight: bold;
    }
    @include media("<700px") {
      p {
        font-size: 1rem;
      }
      > img {
        height: 100px;
      }
      footer {
        font-size: .9rem;
      }
    }
  }
  
  .button-box {
    background: rgba(255, 255, 255, 0.5);
    margin-bottom: 20px;
    border-radius: 50px;
    button {
      margin: 0;
      border: none;
      outline: none;
      line-height: 0;
      padding: 5px;
      background: none;
      cursor: pointer;

      img {
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        border: 4px solid transparent;
        transition: border .2s ease, transform .2s ease;
      }

      &:hover, &:focus {
        img {
          transform: scale(1.3);
          border: 4px solid white;
        }
      }

      &.active {
        cursor: auto;
        img {
          border: 4px solid $primary-color;
        }
      }
      
      
    }
  }
  
}
