$medium-brand-color: #00ab6c;
$center-at: "<1000px";

#coming-soon {
  position: relative;
  z-index: 3;
  overflow: hidden;
  header {
    position: absolute;
    font-size: calc(3vw + 2vh + .5rem);
    @include media($center-at) {
      font-size: calc(1vw + 1vh + 1rem);
      color: black;
      text-shadow: 1px 1px 4px white;
    }
    margin-top: 30px;
    margin-left: 20px;
    color: rgb(53, 73, 94);
    opacity: .5;
      transition: opacity 1s ease,transform 3s ease;
    &:hover {
      opacity: .8;
      transform: scale(3);
    transition: opacity 1s ease,transform 20s ease;
      
    }
    
    p {
      position: relative;
      left: 20%;
    }
  }
  img {
    width: 100%;
    margin-bottom: -5px;
  }
}

.blogging {
  position: relative;
  background: white;
  z-index: 3;
  width: 100%;
  padding: 10vmin 5%;
  min-height: 110vh;
  background: lighten($medium-brand-color, 64%);
  
  > h1 img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    z-index: 6;
    position: relative;
    background: black;
    
    @include media($center-at) {
      margin-bottom: 30px;
    }
  }
  
  .blog-post-grid {
    display: inline-block;
    width: 100%;
    margin-top: -15px;
  }
  
  
  #backendification {
    background-image: url(../../img/Backendification.jpeg);
    z-index: 5;
    float: left;
    .publication-badge {
      position: absolute;
      top: 10%;
      left: -4.5%;
      width: 66%;
    }
  }
  
  #simulations {
    background-image: url(../../img/simulations.jpeg);
    float: right;
    margin-top: -10%;
    
  }
  
  #medium-stats-image {
    max-width: 100%;
    margin-top: -70px;
    @include media($center-at) {
      margin-top: 0;
    }
  }
  
  #medium-stats {
    margin-top: 40px;
    text-align: center;
    font-size: calc(.5vw + .5vh + 1.5rem);
    font-weight: bold;
    li {
      list-style-type: none;
      line-height: 1.5;
      font-weight: bold;
      transition: color .5s ease;
      &:hover {
        color: $medium-brand-color;
      }
    }
  }
  
  section {
    background-position: center;
    background-size: cover;
    height: 0;
    width: 50vw;
    position: relative;
    padding-bottom: 50%;
    
    @include media($center-at) {
      width: 80%;
      float: none !important;
      margin: 50px !important;
      position: relative;
      
    }
    
    h1 a {
      display: block;
      position: absolute;
      font-weight: normal;
      left: 25%;
      bottom: -5%;
      color: black;
      background: white;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
      transition: box-shadow 1s ease;
      strong {
        transition: color 1s ease;
      }
      &:hover,&:focus {
        box-shadow: 0px 0px 50px $medium-brand-color;
        strong {
          color: $medium-brand-color;
        }
      }
      padding: 10px;
      font-size: calc(.5vw + .5vh + 1.5rem);
      text-decoration: none;
      width: 80%;
      text-align: center;
      
      @include media($center-at) {
        font-size: calc(.5vw + .5vh + 1rem);
      }
    }
  }
  
}