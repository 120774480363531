@import "variables";

/* Banner */
.banner {
  position: relative;
  background-image: url(../../img/mountains-min.jpg);
  background-size: cover;
  background-position: 0px 0px;
  height: 110vh;
  width: 100%;
  overflow: hidden;
  z-index: 8;
  
  
  h1 {
    color: red;
    margin: 0;
    postion: absolute;
    bottom: 100px;
    left: 0;
  }
    
  h2 {
    position: fixed;
    display: inline-block;
    top: 20px;
    left: 50px;
    font-size: 5vw;
    //width: 50%;
    text-align: center;
    color: white;
    font-family: $heading-font;
    text-shadow: 1px 1px 5px black;
    line-height: 1.5;
    
    @include media("<1400px") {
      font-size: 8vmin;
    }
  }
  
  .masked {
    position: absolute;
    top: 0;
    left: 0;
    mask-image: url(../../img/mountains-mask.svg);
    mask-size: cover;
    mask-position: 0px 0px;
    height: 100%;
    width: 100%;
  }

  @include media("<900px") {
    background-position: 30% 0px;
    .masked {
      mask-position: 30% 0px;
    }
    h2 {
      font-size: calc(2vmin + 2vmax + 2vw);
    }
  }
}


.banner .mktlr {
  display: flex;
  position: absolute;
  bottom: 20vh;
  justify-content: center;
  width: 100%;
  
  margin: 0;
  margin-bottom: 20px;
  font-family: $heading-font;
  font-size: 15vmin;
  text-shadow: 1px 1px 5px black;
  color: white;
  line-height: 1;
  user-select: none;
  //transition: font-size 1s ease;
  transition: font-size 2s;
  
  .hidden {
    transition: opacity 2s, max-width 2s;
    opacity: 1;
    max-width: 11vmin;
  }

  &.collapsed {
    font-size: 25vmin;
   .hidden {
      opacity: 0;
      max-width: 0;    
    }    
  }
}