*, *:before, *:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  &:not(.contact-button) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.01);
  }
}

::selection {
  background: #2894be;
  color: white;
}

html, body {
  font-family: $paragraph-font;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
}