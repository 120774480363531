.linkedin {
  position: fixed;
  bottom: 7%;
  right: -1px;
  z-index: 9999999;
  background: white;
  display: block;
  font-size: 40px;
  line-height: 0;
  padding: 9px;
  border-radius: 10px 0 0 10px;
  transition: transform 5s;
  transform-origin: right;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  #linkedin-qr {
    display: none;
  }
  
  @include media(">800px") {
    #linkedin-qr {
      display: block;
      img {
        width: 250px;
      }
    }
    #linkedin-logo {
      display: none;
    }
  }
  
  &:hover {
    transition: transform .5s ease;
    transform: scale(1.2);
  }
  
  &.hidden {
    transition: transform .5s;
    transform: translate(100%);
  }
  
  a {
    color: gray;
    transition: color 2s ease;
    &:hover {
      color: #0077b5;
      transform: rotate(1turn);
    }
  }
}