#contact {
  position: relative;
  background: #697680;
  z-index: 3;
  width: 100%;
  padding: 10vmin 5%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);


  h1,
  > p {
    color: white;
    text-align: center;
    margin-bottom: 30px;

  }
  h1 {
    font-size: 5vmax;
  }
  > p {
    font-size: 3vmax;
  }


  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 700px;
    margin: 0 auto;
    grid-gap: 20px;
    align-items: center;
  }

  .name,
  .company,
  .recaptcha {
    grid-column: 1 / span 1;
  }
  .email,
  .phone,
  .submit {
    grid-column: 2 / span 1;
  }
  .message {
    grid-column: 1 / span 2;
  }

  .recaptcha,
  .submit {
    justify-self: center;
  }

  @include media("<800px") {
    .name,
    .email,
    .phone,
    .company,
    .recaptcha,
    .submit {
      grid-column: 1 / span 2;
    }

    .name,
    .email,
    .phone,
    .company {
      input {
        max-width: 500px;
      }
    }
  }

  input {
    &[type="text"],
    &[type="email"],
    &[type="tel"] {
      background: transparent;
      border: none;
      outline: none;
      display: block;
      padding: 10px;
      transition: background .5s ease;
      font-size: 1.5rem;
      caret-color: white;
      color: white;
      border-bottom: 1px solid white;
      width: 100%;

      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  textarea {
    resize: none;
    background: transparent;
    border: none;
    display: block;
    padding: 10px;
    outline: none;
    transition: background .5s ease;
    font-size: 1.5rem;
    caret-color: white;
    color: white;
    border-bottom: 1px solid white;
    width: 100%;
    height: 300px;
    display: block;
    margin-bottom: 40px;
    &:hover,
    &:focus {
      background: rgba(255, 255, 255, 0.1);
    }
  }
  label {
    font-size: 1rem;
    color: white;
    text-transform: uppercase;
    width: 100%;
    display: inline-block;
  }

  .hidden {
    visibility: hidden;
    position: absolute;
  }

  button[type="submit"] {
    box-sizing: border-box;
    margin: 0 auto;
    display: block;
    background: white;
    padding: 20px 30px;
    color: #697680;
    font-weight: bold;
    font-size: 1.2rem;
    background: white;
    border: none;
    border-radius: 10px;
    transition: box-shadow .5s ease, transform .5s ease, background .3s ease, color .3s ease;
    outline: none;
    clear: both;

    &:disabled {
      background: #1a2529;
    }

    &:hover,
    &:focus {
      background: #2894be;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
      color: white;
    }

  }

  ::placeholder {
    color: white;
    opacity: .3;
    /* Firefox */
  }
}
