.bio {
  min-height: 110vh;
  background: white;
  position: relative;
  padding: 100px 5%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.57);
  overflow: hidden;
  z-index: 9;
  
  &:before {
    position: absolute;
    width: 66vmax;
    height: 66vmax;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background: rgb(241, 236, 255);
    content: "";
    display: block;
    z-index: -1;
    border-radius: 50%;
    opacity: .2;
    border: 10vmax double white;
  }
  &:after {
    position: absolute;
    width: 66vmax;
    height: 66vmax;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
    background: rgb(227, 239, 254);
    content: "";
    display: block;
    z-index: -1;
    border-radius: 50%;
    opacity: .2;
    border: 10vmax double white;
  }
  
  p {
    margin-bottom: 20px;
    line-height: 1.45;
    font-size: calc(.25vw + .25vh + .9rem);
  }
  
  .bio-wrapper {
    position: relative;
    z-index: 3;
    max-width: 500px;
    text-align: justify;
  }
  
  h1 {
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .headshot {
    position: relative;
    margin-top: -50px;
    margin-left: -75px;
    padding: 10px;
    border-radius: 15% 50% 50% 50%;
    right: 0;
    width: 200px;
    height: 200px;
    float: left;
    shape-outside: border-box;
    background-size: contain;
    background-clip: content-box;
    background-image: url(../../img/headshot.jpg);
    
    
    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 15% 50% 50% 50%;
      padding: 10px;
      height: 200px;
      width: 200px;
      background-size: contain;
      background-clip: content-box;
      opacity: 0;
      transition: opacity 1s ease; 
      z-index: 1;
    }
    
    @include media ("<700px") {
      margin-left: -10px;
      margin-top: -10px;
      width: 150px;
      height: 150px;
      
      &:before, &:after {
        width: 150px;
        height: 150px;
      }
    }
    
    &:before {
      background-image: url(../../img/headshot-line.png);
    }
    &.line:before {
      opacity: 1;
    }
    
    &:after {
      background-image: url(../../img/headshot-pixels-plain.png);
    }
    &.pixel:after {
      opacity: 1;
    }
  }
  
  .contact-button {
    outline: none;
    text-decoration: none;
    background: #2894be;
    border-radius: 15px;
    padding: 20px;
    color: white;
    align-self: center;
    transform: rotate(-7deg);
    transition: transform .3s ease, box-shadow .3s ease, top .3s ease, left .3s ease;
    position: relative;
    top: 0;
    left: 0;
    margin-top: 50px;
    transform-origin: center -50px;
    font-weight: bold;
    box-shadow: 5px 5px 10px rgba(111, 111, 111, 0.5);
    cursor: pointer;
    
    &:hover, &:focus {
      transform: rotate(0deg);
      &:active {
        box-shadow: none;
        transform: rotate(0deg);
        //top: 2px;
      }
    }
    
    .line-left {
      display: block;
      position: absolute;
      transform: rotate(-45deg);
      transform-origin: left;
      height: 2px;
      width: 75px;
      top: 10px;
      left: 20px;
      background: white;
      z-index: 2;
      box-shadow: 3px 3px 5px black;
      
      &:before {
        content: "";
        display: block;
        height: 10px;
        width: 10px;
        position: absolute;
        left: -5px;
        top: -5px;
        z-index: -1;
        border-radius: 50%;
        background: white;
        box-shadow: inset 1px 1px 3px black;
      }
      
      &:after {
        content: "";
        display: block;
        height: 10px;
        width: 10px;
        position: absolute;
        right: -5px;
        top: -5px;
        border-radius: 50%;
        background: #b1b7b9;        
        box-shadow: 2px 3px 5px black;
      }
    }
    
    .line-right {
      display: block;
      position: absolute;
      transform: rotate(45deg);
      transform-origin: right;
      height: 2px;
      width: 75px;
      top: 10px;
      right: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 5px black;
      
      &:before {
        content: "";
        display: block;
        height: 10px;
        width: 10px;
        position: absolute;
        right: -5px;
        top: -5px;
        z-index: -1;
        border-radius: 50%;
        background: white;
        box-shadow: inset 1px 1px 3px black;
      }
    }
    
  }
}
