.services {
  display: block;
  padding: 50px 20px;
  position: relative;
  z-index: 9;
  background: white;

  .grid {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 150px;
    grid-gap: 20px;

    @include media("<829px") {
      grid-gap: 15px;
      grid-auto-rows: 100px;
    }
    
    @include media("<560px") {
      grid-gap: 10px;
    }
  }


  .content {
    grid-area: 1/1/span 2/span 2;

    @include media("<829px") {
      grid-area: 3/1/span 2/span 2;
    }

    @include media("<560px") {
      grid-area: 5/1/span 2/span 1;
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    overflow: hidden;
    padding: 20px;
    border-radius: 0 20px 20px 20px;

    h1 {
      font-family: $heading-font;
      font-size: calc(.5vw + .25vh + 2rem);
      color: #2894be;
      color: #63c6ec;
      position: relative;
      z-index: 3;
      margin-top: auto;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    }
    p {
      font-size: calc(.5vw + .25vh + 1.25rem);
      position: relative;
      z-index: 3;
      color: white;
    }
  }

  .content {
    // Background
    outline: none;
    &:before {
      content: "";
      position: absolute;
      background-image: url(../../img/cloudy-mountains-min.jpg);
      background-size: cover;
      z-index: 1;
      top: -50px;
      bottom: -50px;
      left: -50px;
      right: -50px;
      transition: top 1s ease,
      bottom 1s ease,
      left 1s ease,
      right 1s ease;
    }

    &:after {
      // Mask
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(50, 50, 50, 0.7);
      z-index: 2;
    }

    &:hover,
    &:focus {
      // Zoom
      &:before {
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }


  .item {
    outline: none;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
    background: #63c6ec;
    padding: 10px;
    font-family: $heading-font;
    text-align: center;
    color: white;
    border-radius: 0 20px 20px 20px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    transition: box-shadow 1s ease, transform .3s ease, background 1s ease;
    top: 0;

    &.visited {
      background: #2894be;
    }

    &:hover,
    &:focus {
      transform: scale(1.02);
      box-shadow: inset -3px -3px 15px #2eacdd, 3px 3px 5px rgba(0, 0, 0, 0.3);
    }
  }

  .item {
    // Item Text
    h1 {
      position: relative;
      top: 0;
      opacity: 1;
      transition: top 1s ease-out, opacity .5s ease;
      font-size: calc(.25vw + .25vh + 1.2rem);
    }
    p {
      color: white;
      text-shadow: 1px 1px 3px black;
      font-size: 25px;
      font-size: calc(.25vw + .25vh + 1rem);
      text-align: center;
      width: 100%;
    }
    &:hover h1,
    &:focus h1 {
      top: -50px;
      opacity: 0;
    }
    .centered {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .01s ease;
      height: 100%;
      width: 100%;
      display: flex;
      padding: 20px;
      align-items: center;
    }
    &:hover .centered,
    &:focus .centered {
      opacity: 1;
    }
  }

  .item {
    // Background Icons
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 125px;
      opacity: .1;
      transition: transform .7s ease;
      text-shadow: none;
      transform: translate(-50%, -50%) rotate(-20deg) scale(2);

      @include media("<829px") {
        font-size: 75px;
      }

    }
    &:hover i,
    &:focus i {
      transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
    
  }
}
