.tech {
  background: white;
  position: relative;
  z-index: 8;
  perspective: 1000px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(../../img/dev-min.jpg);
  background-size: cover;
  background-attachment: fixed;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  padding: 5%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  
  
  .text {
    grid-column: 1;
    position: relative;
    text-align: center;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.5);
    padding: 10%;
    border-radius: 0 10px 10px 10px;
    transition: background 1s ease;
    
    &:hover {
      background: rgba(0, 0, 0, 0.8);
    }
    
    h1 {
      font-size: calc(.25vw + .25vh + 1.75rem);
      //font-size: 2vmax;
    }
    strong {
      font-size: calc(.25vw + .25vh + 2.75rem);
    }
    
    p {
      font-size: calc(.25vw + .25vh + 1rem);
      margin-bottom: 10px;
      a, a:visited {
        color: white;
        transition: transform .2s ease
      }
      a:hover, a:focus {
        transform: scale(1.2);
      }
      
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  @include media("<1000px") {
    grid-template-columns: 1fr;
  }
  
  .tools {
    grid-column: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    opacity: .8;
    
    @include media("<1000px") {
      grid-column: 1;
      grid-row: 1;
    }
    
    img {
      margin: 5px;
      background: white;
      width: 100px;
      height: 100px;
      object-fit: scale-down;
      object-position: center center;
      padding: 10px;
      border-radius: 0 10px 10px 10px;
      
      @include media("<900px") {
        width: 75px;
        height: 75px;
      }
      
      @include media("<600px") {
        width: 60px;
        height: 60px;
        padding: 5px;
        margin: 3px;
      }
    }
  }
  
  
  .tools {
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    width: 100%;
    top: 0;
    left: 0;
  }
  
  
  i {
    font-size: 10vmax;
    color: rgba(0, 0, 0, 0.2);
    transition: color 1s ease;
    margin: 10px;
    text-align: center;
  }
}